.range-slider {
  appearance: none;
  outline: none;
  width: 200px;
  height: 3px;
  background-color: #ccc;
}

.range-slide::-webkit-slider-runnable-track {
  background-color: white;
}
.range-slider::-webkit-slider-thumb {
  background: #fafafa;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.room-container {
  height: 100vh;
  overflow: auto;
}

.search-and-playlist {
  width: 100%;
}

@media (min-width: 1000px) {
  .playlist-container {
    display: flex;
    justify-content: space-around;
  }

  .search-and-playlist {
    max-width: 600px;
    margin-left: 30px;
  }
}

.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.2);
}

.drawer-menu {
  height: 100%;
  z-index: 2;
  background-color: #27212b;
  width: 225px;
  position: fixed;
  right: 0;
  transform: translateX(225px);
  transition: transform 200ms ease-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.drawer-menu.active {
  transform: translateX(0px);
}

.drawer-menu .user-info {
  display: flex;
  align-items: center;
}

.user-info img {
  border-radius: 100%;
  width: 40px;
  margin-right: 10px;
}

.room-info {
  padding: 10px;
}

.app-bar {
  padding: 20px;
  justify-content: space-between;
  background-color: #27212b;
  margin-bottom: 20px;
}

.fab-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 30px;
  right: 30px;
  background-color: #feac08;
  border-radius: 20px;
  height: 50px;
}

.icon-button {
  position: relative;
  background-color: transparent;
  padding: 0;
  display: flex;
}

.icon-button .delete {
  color: red;
}

.menu-button {
  height: 50px;
  width: 100%;
  display: flex;
  padding-left: 10px;
  font-size: 15px;
  background: transparent;
  align-items: center;
}

.menu-button svg {
  margin-right: 10px;
}

.suggestions-container {
  margin-top: 0;
  background-color: #27212b;
  position: absolute;
  width: 100%;
  top: 46px;
  z-index: 2;
}

.suggestions-container button {
  padding: 10px 20px 10px 20px;
  width: 100%;
  background-color: transparent;
  text-align: left;
  font-size: 15px;
}

.buy-tokens-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.heading {
  background-color: transparent;
  font-family: Heebo, sans-serif;
  color: #fff;
  font-size: 30px;
  font-weight: 800;
}

.paragraph {
  font-family: Heebo, sans-serif;
  color: #fff;
  font-size: 20px;
  line-height: 30px;
  font-weight: 300;
  text-align: center;
}

.token-number-selector {
  display: flex;
  width: 70%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.button {
  display: flex;
  border-radius: 12px;
  background-color: #ff5252;
  font-size: 20px;
}

.button.add {
  background-color: #79b36e;
}

.pay-btn {
  font-size: 1em;
  margin-bottom: 15px;
  border-radius: 20px;
  height: 40px;
  background-color: #fff;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#f85500),
    to(#faaf01)
  );
  background-image: linear-gradient(90deg, #f85500, #faaf01);
  opacity: 1;
  padding: 0.5rem 1rem;
  margin-bottom: 2rem;
}
