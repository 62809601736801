.music-item {
  display: flex;
}

.album-cover {
  display: flex;
  margin: 0 15px 0 0;
  position: relative;
}

.slightly-rounded {
  border-radius: 10px;
}

.cover {
  object-fit: cover;
}

.album-cover > .album-cover-img {
  object-position: center;
}

.album-cover > .owner-url-img {
  position: absolute;
  width: 30px;
  border-radius: 100%;
  bottom: -5px;
  right: -5px;
}

.album-cover > .owner-url-img.crop-centered {
  background-position: center;
  background-size: cover;
  height: 30px;
}

.music-info {
  min-width: 0;
  flex: 1;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.music-info.currently-playing {
  color: #b7f0ad;
}

.music-info .body {
  display: flex;
  flex-direction: column;
  min-width: 50px;
}

.music-info .title,
.music-info .artist {
  margin: 0;
  font-size: 1em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.music-info .icon {
  flex-shrink: 0;
}

.icon {
  transition: color 200ms ease-out, transform 200ms ease-out;
}

.delete {
  transform: rotate(45deg);
}

.title {
  font-weight: bold;
}

.upvote-counter {
  bottom: -5px;
  left: -5px;
  color: black;
  width: 15px;
  height: 15px;
  border-radius: 20px;
  position: absolute;
  background-color: white;
  font-size: 0.9em;
  border: 1px solid grey;
}

.speaker-cover {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.4;
  display: flex;
  border-radius: 10px;
}

.speaker-cover svg {
  margin: auto;
}

.MyMenuButton {
  position: relative;
}

.MyMenuButton-menu {
  background-color: #27212b;
  z-index: 2;
  position: absolute;
  padding: 0 1.5rem;
  cursor: pointer;
  border-radius: 5px;
  top: 60px;
}
