@import url("https://fonts.googleapis.com/css?family=Heebo:300,400,800&display=swap");

body {
  background-color: #1d0c27 !important;
  color: white;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hiddenScrollbar::-webkit-scrollbar {
  display: none !important;
}

.hiddenScrollbar {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

.firebase-emulator-warning {
  display: none !important;
}

input[type="password"],
input[type="search"],
input[type="text"] {
  font-size: 16px;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  padding: 0.5rem;
  border: none;
  color: white;
  border-radius: 2rem;
  cursor: pointer;
  outline: none;
  font-family: "Heebo", sans-serif;
  font-weight: 400;
}

.input-container {
  border-radius: 2rem;
  margin-bottom: 20px;
  background-color: white;
  color: black;
  padding: 0 0.8rem 0 0.8rem;
  box-sizing: border-box;
}

input {
  box-sizing: border-box;
  padding: 0.8rem;
  border: none;
  border-radius: 2rem;
  font-size: 0.8em;
  outline: 0;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex {
  display: flex;
}

.flex.items-center {
  align-items: center;
}

.flex.justify-center {
  justify-content: center;
}

.flex.wrap {
  flex-wrap: wrap;
}

.flex.vertical,
.container.vertical {
  flex-direction: column;
}

.container-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.full-height-screen {
  height: 100vh;
}

figure {
  margin-bottom: 0;
  margin-top: 0;
}

.logo {
  width: 120px;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

a {
  color: white;
}

.route-wrapper {
  position: relative;
}

.route-wrapper > div {
  position: absolute;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.icon {
  font-size: 25px;
}

.icon.input {
  color: grey;
}

ul {
  list-style: none;
  padding: 0;
}

.update-notification {
  position: fixed;
  background-color: #27212b;
  height: 100px;
  bottom: 0;
  width: 100vw;
  max-width: 450px;
  font-family: "Heebo", sans-serif;
  font-weight: 400;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 10px;
  box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.3);
}

.update-notification span {
  width: 50%;
}

.update-notification div {
  display: flex;
}

.update-notification .update-btn {
  background: rgb(248, 85, 0);
  background: linear-gradient(
    90deg,
    rgba(248, 85, 0, 1) 0%,
    rgba(250, 175, 1, 1) 100%
  );
  font-size: 15px;
  margin-right: 10px;
}

.update-notification .dismiss-btn {
  background: none;
  margin-right: 10px;
  text-decoration: underline;
  font-size: 15px;
  padding: 0;
}

.ct-toast {
  color: white !important;
  background-color: #27212b !important;
}

.m-0 {
  margin: 0;
}
.mb-0 {
  margin-bottom: 0;
}

.pb-1 {
  padding-bottom: 1rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mt-0 {
  margin-top: 0;
}

.pt-1 {
  padding-top: 1rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mr-05 {
  margin-right: 0.5rem;
}

.mr-1 {
  margin-right: 1rem;
}

.font-size-small {
  font-size: 1em;
}

.font-size-medium {
  font-size: 1.5em;
}

.font-size-large {
  font-size: 2em;
}

.rounded-full {
  border-radius: 100%;
}

.zero-padded {
  padding: 0;
}

.horizontal-scrollable {
  overflow: scroll;
}

.horizontal-scrollable::-webkit-scrollbar {
  display: none;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

.bg-red {
  background-color: #ff5252 !important;
}

.test-enter {
  opacity: 0.01;
}

.test-enter.test-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.test-leave {
  opacity: 1;
}

.test-leave.test-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.up-voted {
  color: #f97d00;
}

.text-center {
  text-align: center;
}
