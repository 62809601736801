@import url(https://fonts.googleapis.com/css?family=Heebo:300,400,800&display=swap);
body {
  background-color: #1d0c27 !important;
  color: white;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hiddenScrollbar::-webkit-scrollbar {
  display: none !important;
}

.hiddenScrollbar {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

.firebase-emulator-warning {
  display: none !important;
}

input[type="password"],
input[type="search"],
input[type="text"] {
  font-size: 16px;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  padding: 0.5rem;
  border: none;
  color: white;
  border-radius: 2rem;
  cursor: pointer;
  outline: none;
  font-family: "Heebo", sans-serif;
  font-weight: 400;
}

.input-container {
  border-radius: 2rem;
  margin-bottom: 20px;
  background-color: white;
  color: black;
  padding: 0 0.8rem 0 0.8rem;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

input {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0.8rem;
  border: none;
  border-radius: 2rem;
  font-size: 0.8em;
  outline: 0;
}

.container {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
}

.flex {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
}

.flex.items-center {
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
}

.flex.justify-center {
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
}

.flex.wrap {
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.flex.vertical,
.container.vertical {
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
}

.container-space-between {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
          justify-content: space-between;
}

.full-height-screen {
  height: 100vh;
}

figure {
  margin-bottom: 0;
  margin-top: 0;
}

.logo {
  width: 120px;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

a {
  color: white;
}

.route-wrapper {
  position: relative;
}

.route-wrapper > div {
  position: absolute;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.icon {
  font-size: 25px;
}

.icon.input {
  color: grey;
}

ul {
  list-style: none;
  padding: 0;
}

.update-notification {
  position: fixed;
  background-color: #27212b;
  height: 100px;
  bottom: 0;
  width: 100vw;
  max-width: 450px;
  font-family: "Heebo", sans-serif;
  font-weight: 400;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  padding-left: 10px;
  -webkit-box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.3);
          box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.3);
}

.update-notification span {
  width: 50%;
}

.update-notification div {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
}

.update-notification .update-btn {
  background: rgb(248, 85, 0);
  background: -webkit-gradient(
    linear,
    left top, right top,
    from(rgba(248, 85, 0, 1)),
    to(rgba(250, 175, 1, 1))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(248, 85, 0, 1) 0%,
    rgba(250, 175, 1, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(248, 85, 0, 1) 0%,
    rgba(250, 175, 1, 1) 100%
  );
  font-size: 15px;
  margin-right: 10px;
}

.update-notification .dismiss-btn {
  background: none;
  margin-right: 10px;
  text-decoration: underline;
  font-size: 15px;
  padding: 0;
}

.ct-toast {
  color: white !important;
  background-color: #27212b !important;
}

.m-0 {
  margin: 0;
}
.mb-0 {
  margin-bottom: 0;
}

.pb-1 {
  padding-bottom: 1rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mt-0 {
  margin-top: 0;
}

.pt-1 {
  padding-top: 1rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mr-05 {
  margin-right: 0.5rem;
}

.mr-1 {
  margin-right: 1rem;
}

.font-size-small {
  font-size: 1em;
}

.font-size-medium {
  font-size: 1.5em;
}

.font-size-large {
  font-size: 2em;
}

.rounded-full {
  border-radius: 100%;
}

.zero-padded {
  padding: 0;
}

.horizontal-scrollable {
  overflow: scroll;
}

.horizontal-scrollable::-webkit-scrollbar {
  display: none;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

.bg-red {
  background-color: #ff5252 !important;
}

.test-enter {
  opacity: 0.01;
}

.test-enter.test-enter-active {
  opacity: 1;
  -webkit-transition: opacity 500ms ease-in;
  transition: opacity 500ms ease-in;
}

.test-leave {
  opacity: 1;
}

.test-leave.test-leave-active {
  opacity: 0.01;
  -webkit-transition: opacity 300ms ease-in;
  transition: opacity 300ms ease-in;
}

.up-voted {
  color: #f97d00;
}

.text-center {
  text-align: center;
}

.loading {
  width: 50px;
  height: 25px;
  text-align: center;
  font-size: 10px;
  margin: auto;
}

.loading > div {
  margin-right: 3px;
  background-color: #fff;
  height: 100%;
  width: 2px;
  display: inline-block;
  -webkit-animation: sa-stretchdelay 1.4s infinite ease-in-out;
  animation: sa-stretchdelay 1.4s infinite ease-in-out;
}
.loading .rectangle_2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.loading .rectangle_3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.loading .rectangle_4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.loading .rectangle_5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
@keyframes sa-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
@-webkit-keyframes sa-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

.music-item {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
}

.album-cover {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  margin: 0 15px 0 0;
  position: relative;
}

.slightly-rounded {
  border-radius: 10px;
}

.cover {
  object-fit: cover;
}

.album-cover > .album-cover-img {
  object-position: center;
}

.album-cover > .owner-url-img {
  position: absolute;
  width: 30px;
  border-radius: 100%;
  bottom: -5px;
  right: -5px;
}

.album-cover > .owner-url-img.crop-centered {
  background-position: center;
  background-size: cover;
  height: 30px;
}

.music-info {
  min-width: 0;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
          flex: 1 1;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
          justify-content: space-between;
}

.music-info.currently-playing {
  color: #b7f0ad;
}

.music-info .body {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  min-width: 50px;
}

.music-info .title,
.music-info .artist {
  margin: 0;
  font-size: 1em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.music-info .icon {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
}

.icon {
  -webkit-transition: color 200ms ease-out, -webkit-transform 200ms ease-out;
  transition: color 200ms ease-out, -webkit-transform 200ms ease-out;
  transition: color 200ms ease-out, transform 200ms ease-out;
  transition: color 200ms ease-out, transform 200ms ease-out, -webkit-transform 200ms ease-out;
}

.delete {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.title {
  font-weight: bold;
}

.upvote-counter {
  bottom: -5px;
  left: -5px;
  color: black;
  width: 15px;
  height: 15px;
  border-radius: 20px;
  position: absolute;
  background-color: white;
  font-size: 0.9em;
  border: 1px solid grey;
}

.speaker-cover {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.4;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  border-radius: 10px;
}

.speaker-cover svg {
  margin: auto;
}

.MyMenuButton {
  position: relative;
}

.MyMenuButton-menu {
  background-color: #27212b;
  z-index: 2;
  position: absolute;
  padding: 0 1.5rem;
  cursor: pointer;
  border-radius: 5px;
  top: 60px;
}

.player {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-width: 1025px) {
  .player {
    position: relative;
    margin-bottom: 0;
    height: 0;
    max-height: 100vh;
  }
}

.line {
  height: 1px;
  background-color: white;
}

.button-container {
  width: 100%;
  margin-bottom: 1rem;
}

.login-button {
  height: 40px;
  font-size: 1.1em;
  background: rgb(248, 85, 0);
  background: -webkit-gradient(
    linear,
    left top, right top,
    from(rgba(248, 85, 0, 1)),
    to(rgba(250, 175, 1, 1))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(248, 85, 0, 1) 0%,
    rgba(250, 175, 1, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(248, 85, 0, 1) 0%,
    rgba(250, 175, 1, 1) 100%
  );
}

.orange-gradient-bg {
  background: rgb(248, 85, 0);
  background: -webkit-gradient(
    linear,
    left top, right top,
    from(rgba(248, 85, 0, 1)),
    to(rgba(250, 175, 1, 1))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(248, 85, 0, 1) 0%,
    rgba(250, 175, 1, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(248, 85, 0, 1) 0%,
    rgba(250, 175, 1, 1) 100%
  );
}

.social-login-button {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  font-size: 1.1em;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  width: 100%;
  height: 40px;
}

.social-login-button.email {
  background: rgb(248, 85, 0);
  background: -webkit-gradient(
    linear,
    left top, right top,
    from(rgba(248, 85, 0, 1)),
    to(rgba(250, 175, 1, 1))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(248, 85, 0, 1) 0%,
    rgba(250, 175, 1, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(248, 85, 0, 1) 0%,
    rgba(250, 175, 1, 1) 100%
  );
}

.social-login-button svg {
  left: 0.5rem;
  position: absolute;
}

.facebook {
  background-color: #335496;
  padding-left: 1.5rem;
}

.google {
  background-color: #4085fa;
}

.google-container {
  position: absolute;
  left: 0;
  background-color: white;
  border-radius: 20px 0 0 20px;
  height: 41px;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  padding-right: 0.2rem;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  width: 40px;
}

.google figure {
  margin: 0 0 0 0.5rem;
  width: 2rem;
  height: 2rem;
}

.google figure img {
  margin: 0;
  width: 100%;
}

.apple {
  background-color: white;
  color: black;
}

.facebook-login-button svg {
  margin-right: 15px;
}

.absolute-top-left {
  position: absolute;
  top: -webkit-calc(env(safe-area-inset-top) + 20px);
  top: calc(env(safe-area-inset-top) + 20px);
  left: 30px;
}

.version-tag {
  position: fixed;
  bottom: 0;
}

.facebook-loading {
  margin: 0;
}

.club-logo {
  width: 7rem;
  height: 7rem;
  background-color: #261033;
  border-radius: 100%;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  cursor: pointer;
  background-position: center;
  background-size: cover;
}

.club-logo div {
  border-radius: 100%;
  width: 85%;
  height: 85%;
  border: 1px dashed grey;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  color: grey;
}

.absolute-top-right {
  position: absolute;
  top: 30px;
  right: 30px;
}

.range-slider {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  outline: none;
  width: 200px;
  height: 3px;
  background-color: #ccc;
}

.range-slide::-webkit-slider-runnable-track {
  background-color: white;
}
.range-slider::-webkit-slider-thumb {
  background: #fafafa;
  -webkit-appearance: none;
          appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.room-container {
  height: 100vh;
  overflow: auto;
}

.search-and-playlist {
  width: 100%;
}

@media (min-width: 1000px) {
  .playlist-container {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
  }

  .search-and-playlist {
    max-width: 600px;
    margin-left: 30px;
  }
}

.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.2);
}

.drawer-menu {
  height: 100%;
  z-index: 2;
  background-color: #27212b;
  width: 225px;
  position: fixed;
  right: 0;
  -webkit-transform: translateX(225px);
          transform: translateX(225px);
  -webkit-transition: -webkit-transform 200ms ease-out;
  transition: -webkit-transform 200ms ease-out;
  transition: transform 200ms ease-out;
  transition: transform 200ms ease-out, -webkit-transform 200ms ease-out;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
}

.drawer-menu.active {
  -webkit-transform: translateX(0px);
          transform: translateX(0px);
}

.drawer-menu .user-info {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
}

.user-info img {
  border-radius: 100%;
  width: 40px;
  margin-right: 10px;
}

.room-info {
  padding: 10px;
}

.app-bar {
  padding: 20px;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
          justify-content: space-between;
  background-color: #27212b;
  margin-bottom: 20px;
}

.fab-btn {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-justify-content: center;
     -moz-box-pack: center;
          justify-content: center;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
  position: absolute;
  bottom: 30px;
  right: 30px;
  background-color: #feac08;
  border-radius: 20px;
  height: 50px;
}

.icon-button {
  position: relative;
  background-color: transparent;
  padding: 0;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
}

.icon-button .delete {
  color: red;
}

.menu-button {
  height: 50px;
  width: 100%;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  padding-left: 10px;
  font-size: 15px;
  background: transparent;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
}

.menu-button svg {
  margin-right: 10px;
}

.suggestions-container {
  margin-top: 0;
  background-color: #27212b;
  position: absolute;
  width: 100%;
  top: 46px;
  z-index: 2;
}

.suggestions-container button {
  padding: 10px 20px 10px 20px;
  width: 100%;
  background-color: transparent;
  text-align: left;
  font-size: 15px;
}

.buy-tokens-container {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
          flex-direction: column;
  -webkit-justify-content: flex-start;
     -moz-box-pack: start;
          justify-content: flex-start;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
}

.heading {
  background-color: transparent;
  font-family: Heebo, sans-serif;
  color: #fff;
  font-size: 30px;
  font-weight: 800;
}

.paragraph {
  font-family: Heebo, sans-serif;
  color: #fff;
  font-size: 20px;
  line-height: 30px;
  font-weight: 300;
  text-align: center;
}

.token-number-selector {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  width: 70%;
  -webkit-flex-direction: row;
     -moz-box-orient: horizontal;
     -moz-box-direction: normal;
          flex-direction: row;
  -webkit-justify-content: space-between;
     -moz-box-pack: justify;
          justify-content: space-between;
  -webkit-align-items: center;
     -moz-box-align: center;
          align-items: center;
}
.button {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  border-radius: 12px;
  background-color: #ff5252;
  font-size: 20px;
}

.button.add {
  background-color: #79b36e;
}

.pay-btn {
  font-size: 1em;
  margin-bottom: 15px;
  border-radius: 20px;
  height: 40px;
  background-color: #fff;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#f85500),
    to(#faaf01)
  );
  background-image: -webkit-gradient(linear, left top, right top, from(#f85500), to(#faaf01));
  background-image: -webkit-linear-gradient(left, #f85500, #faaf01);
  background-image: linear-gradient(90deg, #f85500, #faaf01);
  opacity: 1;
  padding: 0.5rem 1rem;
  margin-bottom: 2rem;
}

