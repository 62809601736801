.line {
  height: 1px;
  background-color: white;
}

.button-container {
  width: 100%;
  margin-bottom: 1rem;
}

.login-button {
  height: 40px;
  font-size: 1.1em;
  background: rgb(248, 85, 0);
  background: linear-gradient(
    90deg,
    rgba(248, 85, 0, 1) 0%,
    rgba(250, 175, 1, 1) 100%
  );
}

.orange-gradient-bg {
  background: rgb(248, 85, 0);
  background: linear-gradient(
    90deg,
    rgba(248, 85, 0, 1) 0%,
    rgba(250, 175, 1, 1) 100%
  );
}

.social-login-button {
  display: flex;
  font-size: 1.1em;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
}

.social-login-button.email {
  background: rgb(248, 85, 0);
  background: linear-gradient(
    90deg,
    rgba(248, 85, 0, 1) 0%,
    rgba(250, 175, 1, 1) 100%
  );
}

.social-login-button svg {
  left: 0.5rem;
  position: absolute;
}

.facebook {
  background-color: #335496;
  padding-left: 1.5rem;
}

.google {
  background-color: #4085fa;
}

.google-container {
  position: absolute;
  left: 0;
  background-color: white;
  border-radius: 20px 0 0 20px;
  height: 41px;
  display: flex;
  padding-right: 0.2rem;
  align-items: center;
  width: 40px;
}

.google figure {
  margin: 0 0 0 0.5rem;
  width: 2rem;
  height: 2rem;
}

.google figure img {
  margin: 0;
  width: 100%;
}

.apple {
  background-color: white;
  color: black;
}

.facebook-login-button svg {
  margin-right: 15px;
}

.absolute-top-left {
  position: absolute;
  top: calc(env(safe-area-inset-top) + 20px);
  left: 30px;
}

.version-tag {
  position: fixed;
  bottom: 0;
}

.facebook-loading {
  margin: 0;
}

.club-logo {
  width: 7rem;
  height: 7rem;
  background-color: #261033;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-position: center;
  background-size: cover;
}

.club-logo div {
  border-radius: 100%;
  width: 85%;
  height: 85%;
  border: 1px dashed grey;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: grey;
}
